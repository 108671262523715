import React from 'react';

// Categories.js
function AddSubCategory() {
  return (
    <>
      <h2>Add Sub Categories</h2>
    </>
  );
}

export default AddSubCategory;
