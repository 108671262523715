// UpdateCategory
import React from 'react';

// Categories.js
function UpdateCategory() {
  return (
    <>
      <h2>Update Category</h2>
    </>
  );
}

export default UpdateCategory;
